<template>
  <div class="container">
    <nav-header :title="header_title"></nav-header>

    <div class="newList">
      <div v-for="item,index in newsList" :key="index" class="newsItem" @click="goDetail(item.title,index)">
        <div class="pic">
          <img :src="item.cover" alt="">
        </div>
        <div class="content">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import NavHeader from '../../components/nav-header.vue';

  export default {
    components: {NavHeader},
    data() {
      return {
        header_title: "政策新闻",
        newsList: [
          {title: "“以训强警 以练促战”——仙女湖公安举办2024年辅警能力素质培训班",cover: require('../../assets/img/pic1.png')},
          {title: "人社部等四部门印发《“十四五”职业技能培训规划》2025年实现终身职业技能培训制度更加完善！",cover: require('../../assets/img/pic2.png')},
        ]
      }
    },
    methods: {
      goDetail(title,index) {
        this.$router.push({
          name: 'NewsDetail',
          query: {
            title,
            index
          }
        })
      }
    }
  }
</script>
  
<style>
  .newList {
    display: flex;
    flex-direction: column;
    padding-top: 50px
  }

  .newsItem {
    width: 100%;
    height: 96px;
    border-bottom: 1px solid #f4f4f4;
    display: flex;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
  }

  .newsItem>.pic {
    width: 80px;
    height: 80px;
  }

  .newsItem>.pic>img {
    width: 80px;
    height: 80px;
  }

  .newsItem>.content {
    flex: 1;
    padding-left: 10px;
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>