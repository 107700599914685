<!-- (c) 智汇考题 -->
<template>
    <div class="container">
        <nav-header :title="header_title"></nav-header>
        <template v-if="loading && total == 0">
            <skeletonPaper></skeletonPaper>
        </template>
        <div v-else-if="total > 0">
            <div class="questions">
                <div class="item-question flex-col justify-center" v-if="list[1] > 0">
                    <div class="name">
                        <span>单选题</span>
                    </div>
                    <div class="num">共<span class="ml-5">{{list[1]}}</span> 道题</div>
                    <div class="btn" @click="showCard(1)">去练习</div>
                </div>
                <div class="item-question flex-col justify-center" v-if="list[2] > 0">
                    <div class="name">
                        <span>多选题</span>
                    </div>
                    <div class="num">共<span class="ml-5">{{list[2]}}</span> 道题</div>
                    <div class="btn" @click="showCard(2)">去练习</div>
                </div>
                <div class="item-question flex-col justify-center" v-if="list[5] > 0">
                    <div class="name">
                        <span>判断题</span>
                    </div>
                    <div class="num">共<span class="ml-5">{{list[5]}}</span> 道题</div>
                    <div class="btn" @click="showCard(5)">去练习</div>
                </div>
                <div class="item-question flex-col justify-center" v-if="list[3] > 0">
                    <div class="name">
                        <span>填空题</span>
                    </div>
                    <div class="num">共<span class="ml-5">{{list[3]}}</span> 道题</div>
                    <div class="btn" @click="showCard(3)">去练习</div>
                </div>
                <div class="item-question flex-col justify-center" v-if="list[4] > 0">
                    <div class="name">
                        <span>问答题</span>
                    </div>
                    <div class="num">共<span class="ml-5">{{list[4]}}</span> 道题</div>
                    <div class="btn" @click="showCard(4)">去练习</div>
                </div>
                <div class="item-question flex-col justify-center" v-if="list[6] > 0">
                    <div class="name">
                        <span>题帽题</span>
                    </div>
                    <div class="num">共<span class="ml-5">{{list[6]}}</span> 道题</div>
                    <div class="btn" @click="showCard(6)">去练习</div>
                </div>
            </div>
            <div class="footer-box borderbox">
                <div class="button" @click="goPlay(cid, child, total)"> 全部练习 </div>
            </div>
            <div>
                <van-popup v-model="show_card" round position="bottom" :style="{ height: '70%' }" :lock-scroll="false">
                    <div class="select">
                        <van-tabs swipeable :ellipsis="ellipsis" color="#9355de" title-inactive-color="#333" title-active-color="#111">
                            <van-tab v-for="(item,index) in categories" :key="index" :title="item.name">
                                <div class="padding20X paddingX14 van-bgcolor-white">
                                    <div class="children-box" v-for="(child,ind) in item.children" :key="ind" @click="goPlay(item.id, child.id, child.count)">
                                        <div class="child">
                                            {{child.name}}
                                            <div><van-icon name="arrow" /></div>
                                        </div>
                                    </div>
                                </div>
                            </van-tab>
                        </van-tabs>
                    </div>
                </van-popup>
            </div>
        </div>
        <none v-else></none>
    </div>
</template>
<script>
import Vue from 'vue';
import { Popup, Tab, Tabs, Icon } from 'vant-green';

Vue.use(Popup).use(Tab).use(Tabs).use(Icon);

import { mapState } from "vuex";
import NavHeader from "../../../components/nav-header.vue";
import None from "../../../components/none.vue";
import SkeletonPaper from '../../../components/skeleton/skeletonPaper';

export default {
    components: {
        NavHeader,
        None,
        SkeletonPaper
    },
    data() {
        return {
            header_title: '智能客服',
            loading: true,
            categories: null,
            list: null,
            total: 0,
            show_card: false,
            type: 0,
            ellipsis: false,
            cid: 0,
            child: 0,
        }
    },
    mounted() {
        this.getData();
    },
    computed: {
        ...mapState(["isLogin"]),
    },
    methods: {
        getData() {
            this.loading = true;
            let list = {1:0, 2:0, 3:0, 4:0, 5:0, 6:0};
            let total = 0;
            this.$api.Exam.Collection.Stats().then((res) => {
                this.loading = false;
                res.data.collectionTypes.forEach(e => {
                    list[e.type] = e.count;
                    total += e.count;
                });
                this.list = list;
                this.total = total
            });
        },
        showCard(type) {
            if (!this.show_card && this.type != type) {
                this.$api.Exam.Collection.Stats({
                    type: type,
                }).then((res) => {
                    this.navLoading = false;
                    let categoriesCount = [];
                    res.data.categories.forEach(e => {
                        categoriesCount[e.id] = 0;
                        e.children.forEach(e1 => {
                            categoriesCount[e1.id] = 0;
                        })
                    });
                    res.data.collectionCategories.forEach(e => {
                        res.data.categories.forEach(e1 => {
                            if (e.category == e1.id) {
                                if (categoriesCount[e1.id] == undefined) {
                                    categoriesCount[e1.id] = 0;
                                }
                                categoriesCount[e1.id] += e.count;
                            }
                            e1.children.forEach(e2 => {
                                if (e.category == e2.id) {
                                    if (categoriesCount[e.category] == undefined) {
                                        categoriesCount[e.category] = 0;
                                    }
                                    if (categoriesCount[e1.id] == undefined) {
                                        categoriesCount[e1.id] = 0;
                                    }
                                    categoriesCount[e.category] += e.count;
                                    categoriesCount[e1.id] += e.count;
                                }
                            })
                        })
                    })
                    let categories_count = categoriesCount;
                    let categories = res.data.categories;
                    let count = 0;
                    for (let i = 0; i < categories.length; i++) {
                        categories[i].name = categories[i].name + "(" + categories_count[categories[i].id] + ")";
                        categories[i].count = categories_count[categories[i].id];
                        count = count + categories_count[categories[i].id];
                        if (categories[i].children.length > 0) {
                            let children = categories[i].children;
                            for (let j = 0; j < children.length; j++) {
                                children[j].name = children[j].name + "(" + categories_count[children[j].id] + ")";
                                children[j].count = categories_count[children[j].id];
                            }
                            categories[i].children.unshift({
                                id: 0,
                                name: "全部(" + categories_count[categories[i].id] + ")",
                                count: categories_count[categories[i].id]
                            });
                        }else {
                            categories[i].children.unshift({
                                id: 0,
                                name: "全部(" + categories_count[categories[i].id] + ")",
                                count: categories_count[categories[i].id]
                            });
                        }
                    }
                    categories.unshift({
                        id: 0,
                        name: "全部(" + count + ")",
                        count: count,
                        children: []
                    });
                    categories[0].children[0] = {
                        id: 0,
                        count: count,
                        name: "全部(" + count + ")"
                    };
                    this.categories = categories;
                    this.type = type;
                    console.log(this.categories)
                });
            }
            this.show_card = !this.show_card;
        },
        goPlay(cid, child, count) {
            if (count) {
                console.log(count)
                this.cid = cid;
                this.child = child;
                this.$router.push({
                    name: "AnswerCollectsPlay",
                    query: {
                        type: this.type,
                        cid: cid,
                        child: child
                    },
                });
            }
        },
    }
}
</script>
<style lang="less" scoped>
    .justify-center{
        justify-content: center;
    }
    .flex-row  {
        flex-direction: row;
    }
    .ml-5{
        color: #9355de;
    }
    .info {
        width: 100%;
        padding-top: 74px;
        .pass-score{
            height: 16px;
            font-size: 16px;
            font-weight: 500;
            color: #333;
            line-height: 16px;
        }
        .try-times{
            margin-left: 20px;
        }
    }
    .sum {
        width: 100%;
        height: 13px;
        margin-top: 30px;
        span {
            height: 13px;
            font-size: 13px;
            font-weight: 400;
            color: #666;
            line-height: 13px;
        }
    }
    .questions {
        width: 100%;
        box-sizing: border-box;
        margin-top: 34px;
        padding: 10px 15px 100px 15px;
        .item-question {
            width: 100%;
            height: 83px;
            background: #fff;
            box-shadow: 0 2px 8px 0 hsla(0,0%,40%,.1);
            border-radius: 8px;
            margin-top: 20px;
            padding: 20px 30px;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            position: relative;
            .name {
                width: 100%;
                height: 24px;
                font-size: 16px;
                font-weight: 500;
                color: #333;
                line-height: 24px;
                margin-bottom: 15px;
            }
            .num {
                font-size: 14px;
            }
            .btn {
                position: absolute;
                font-size: 14px;
                font-weight: 400;
                color: #9355de;
                line-height: 14px;
                padding: 5px 13px;
                background: rgba(147,85,222,.15);
                border-radius: 12px;
                top: 33px;
                right: 15px;
            }
        }
    }
    .flex-col {
        display: flex;
        flex-direction: column;
    }
    .footer-box{
        position: fixed;
        bottom: 0;
        width: 100%;
        float: left;
        height: auto;
        background: #fff;
        z-index: 100;
        border-top: 1px solid #e5e5e5;
        .button {
            margin: 10px 15px;
            width: auto;
            height: 50px;
            background: linear-gradient(270deg,#c48df1,#9254de);
            box-shadow: 0 4px 8px 0 hsla(0,0%,40%,.1);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
        }
    }
    .select {
        margin-top: 20px;
    }
    .van-popup--bottom {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .children-box {
        width: 100%;
        height: auto;
        max-height: calc(100% - 94px);
        float: left;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        padding: 0 0 20px 0;
        overflow-x: hidden;
        overflow-y: auto;
        .child {
            width: 100%;
            height: 74px;
            border-radius: 8px;
            border: 1px solid #e5e5e5;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            padding: 25px 15px;
            justify-content: space-between;
        }
    }
</style>