<!-- (c) 智汇考题 -->
<template>
    <div class="content">
        <div class="second-box">
            <div class="model">
                <div class="small-model exam" @click="goExam()">
                  <!-- <van-icon name="feedback" class="icon" color="#FFF" size="28" /> -->
                  <img class="exam_icon" src="../../../assets/img/new/exam.png" alt="">
                  <div class="title"><span>在</span>线考试</div>
                  <div class="title title_y">EXAM</div>
                </div>
                <div class="small-model mock" @click="goMock()">
                  <!-- <van-icon name="edit" class="icon" color="#FFF" size="28" /> -->
                  <img src="../../../assets/img/new/mock.png" alt="">
                  <div class="title"><span>模</span>拟考试</div>
                  <div class="title title_y">MOCK</div>
                </div>
            </div>
        </div>

        <div class="second-box">
            <div class="model">
                <div class="small-model practice" @click="goNews()">
                  <!-- <van-icon name="menu" class="icon" color="#FFF" size="28" /> -->
                  <img src="../../../assets/img/new/practice.png" alt="">
                  <div class="title"><span>政</span>策新闻</div>
                  <div class="title title_y">NEWS</div>
                </div>
                <div class="small-model search" @click="goSignUp()">
                  <!-- <van-icon name="comment" class="icon" color="#FFF" size="28" /> -->
                  <img src="../../../assets/img/new/activity.png" alt="">
                  <div class="title"><span>活</span>动报名</div>
                  <div class="title title_y">SIGN UP</div>
                </div>
            </div>
        </div>
        
        <!-- <div class="second-box">
            <div class="model">
                <div class="small-model practice" @click="goPractice()">
                  <van-icon name="menu" class="icon" color="#FFF" size="28" />
                  <img src="../../../assets/img/new/practice.png" alt="">
                  <div class="title"><span>练</span>习模式</div>
                  <div class="title title_y">PRACTICE</div>
                </div>
                <div class="small-model wrongbook" @click="goWrong()">
                  <van-icon name="comment" class="icon" color="#FFF" size="28" />
                  <img src="../../../assets/img/new/wrong.png" alt="">
                  <div class="title"><span>错</span>题本</div>
                  <div class="title title_y">WRONG</div>
                </div>
            </div>
        </div>
        <div class="second-box">
          <div class="model">
            <div class="small-model collect" @click="goCollect()">
              <van-icon name="first-aid" class="icon" color="#FFF" size="28" />
              <img src="../../../assets/img/new/star.png" alt="">
              <div class="title"><span>收</span>藏集</div>
              <div class="title title_y">COLLECT</div>
            </div>
            <div class="small-model search" @click="goSearch()">
              <van-icon name="search" class="icon" color="#FFF" size="28" />
              <img src="../../../assets/img/new/search.png" alt="">
              <div class="title"><span>搜</span>索题目</div>
              <div class="title title_y">SEARCH</div>
            </div>  
          </div>
        </div> -->
        <!-- <div class="second-box">
          <div class="model">
            <div class="small-model signup" @click="goSignUp()">
              <van-icon name="first-aid" class="icon" color="#FFF" size="28" />
              <img src="../../../assets/img/new/activity.png" alt="">
              <div class="title"><span>活</span>动报名</div>
              <div class="title title_y">SIGN UP</div>
            </div>
          </div>
        </div> -->
      </div>
</template>
<script>
import Vue from 'vue';
import { Icon } from 'vant-green';

Vue.use(Icon);
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState(["isLogin"]),
    },
    methods: {
      goNews() {
        if (!this.isLogin) {
          this.goLogin();
        }else {
          this.$router.push({
            name: 'NewsList',
          })
        }
      },
      goExam() {
          if (!this.isLogin) {
            this.goLogin();
          }else {
            this.$router.push({
              name: 'AnswerExams',
            })
          }
      },
      goMock() {
        if (!this.isLogin) {
            this.goLogin();
        }else {
            this.$router.push({
              name: 'AnswerMocks',
            })
        }
      },
      goPractice(){
        if (!this.isLogin) {
            this.goLogin();
        }else {
            this.$router.push({
              name: 'AnswerLearns',
            })
        }
      },
      goWrong() {
        if (!this.isLogin) {
            this.goLogin();
        }else {
            this.$router.push({
              name: 'AnswerErrors',
            })
        }
      },
      goCollect(){
        if (!this.isLogin) {
            this.goLogin();
        }else {
            this.$router.push({
              name: 'AnswerCollects',
            })
        }
      },
      goSearch(){
        if (!this.isLogin) {
            this.goLogin();
        }else {
            this.$router.push({
              name: 'AnswerSearch',
            })
        }
      },
      goSignUp() {
        if (!this.isLogin) {
          this.goLogin();
        }else {
          this.$router.push({
            name: 'SignUp',
          })
        }
      },
      goLogin() {
        this.$router.push({
          name: "Login",
          query: {
          url: window.location.href,
          },
        });
      }
    }
}
</script>
<style lang="less" scoped>
.content {
    width: 100%;
    margin-top: 10px;
    background: #fff;
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 10px;
    .second-box {
    width: 100%;
    margin: 0 auto;
    height: 140px;
    background: #ffffff;
    border-radius: 0px 0px 8px 8px;
    box-sizing: border-box;
    padding: 0 20px;
    .tit {
      width: 100%;
      height: 22px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
      margin-bottom: 27px;
    }
    .model {
      width: 100%;
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      .normal-model {
        width: 100%;
        height: 82px;
        border-radius: 8px;
        cursor: pointer;
        position: relative;
        padding: 0px 30px;
        box-sizing: border-box;
        background: linear-gradient(90deg, #ff4c72 0%, #ff9996 100%);
        .title {
          margin-top: 26px;
          width: 100%;
          height: 33px;
          font-size: 16px;;
          font-weight: 600;
          color: #ffffff;
          line-height: 33px;
          letter-spacing: 1px;
        }
        .info {
          width: 100%;
          height: 22px;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          line-height: 22px;
          margin-top: 20px;
        }
        .icon {
          width: 50px;
          height: 50px;
          margin-top: 20px;
        }
        &:hover {
          box-shadow: 0px 4px 8px 0px rgba(102, 102, 102, 0.1);
        }
      }
      .small-model {
        width: 50%;
        height: 130px;
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        padding: 20px 20px 0 20px;
        .title {
          width: 100%;
          font-size: 15px;;
          color: #ffffff;
          letter-spacing: 1px;
          span {
            font-size: 24px;
            font-weight: 600;
          }
        }
        .title_y {
          opacity: 0.7;
        }
        .info {
          width: 100%;
          height: 22px;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          line-height: 22px;
          margin-top: 20px;
        }
        img {
          width: 30px;
          height: 30px;
          position: absolute;
          right: 20px;
          bottom: 20px;
        }
        .exam_icon{
          width: 32px;
          height: 32px;
        }
        &:hover {
          box-shadow: 0px 4px 8px 0px rgba(102, 102, 102, 0.1);
        }
      }
      .exam {
        background: linear-gradient(270deg,#34bfff 0%,#1784ed 100%);
        margin-right: 10px;
      }
      .mock {
        background: linear-gradient(270deg,#03e4d9 0%,#00baa6 100%);
        //margin-left: 10px;
      }
      .practice {
          background: linear-gradient(270deg, #ffbf47 0%, #ed8917 100%);
          margin-right: 10px;
      }
      .wrongbook {
          background: linear-gradient(270deg, #ff8383 0%, #ff4040 100%);
          //margin-left: 10px;
      }
      .collect {
        background: linear-gradient(270deg, #c48df1 0%, #9254de 100%);
        margin-right: 10px;
      }
      .search {
          background: linear-gradient(270deg, #9de736 0%, #8bc737 100%);
          //margin-left: 10px;
      }
      .signup {
          background: linear-gradient(270deg,#03e4d9 0%,#00baa6 100%);
          //margin-left: 10px;
      }
    }
  }
}
</style>