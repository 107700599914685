<template>
  <div class="container">
    <nav-header :title="header_title"></nav-header>

    <div style="padding-top:50px;">
      <template v-if="page==0">
        <div>
          <img src="../../assets/img/cont1.jpg" style="width:100%;">
        </div>
      </template>

      <template v-else>
        <div>
          <img src="../../assets/img/cont2.jpg" style="width:100%;">
        </div>
      </template>
    </div>
  </div>
</template>
  
<script>
  import NavHeader from '../../components/nav-header.vue';

  export default {
    components: {NavHeader},
    data() {
      return {
        header_title: "政策新闻",
        page: 0
      }
    },
    mounted() {
      this.header_title = this.$route.query.title
      this.page = this.$route.query.index
    }
  }
</script>
  
<style>
  
</style>